import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PaymentSuccessPage = () => (
  <Layout>
    <SEO title="Payment Success" />
    <div className="max-w-4xl mx-auto w-full text-center p-4">
      <p className="text-lg">Your payment was successful!</p>
    </div>
  </Layout>
)

export default PaymentSuccessPage
